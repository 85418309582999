
.footer-btn {
  display: flex;
  justify-content: space-around;
  .ant-btn {
    width: 100%;
  }
  .violet {
    background: #8f73e5;
    color: white;
  }
  .gray {
    background: #e4e6eb;
  }
}
.ant-input-group .ant-input {
  height: 40px;
}
.ant-form-item {
  .ant-select:not(.ant-select-customize-input) {
    .ant-select-selector {
      width: 100%;
      height: 40px;
      border-radius: 6px;
      padding-top: 4px;
    }
  }
  .ant-select-multiple .ant-select-selector {
    height: auto !important;
  }
  .ant-picker {
    width: 100%;
    height: 40px;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
  }
  .ant-input {
    height: 40px;
  }
  .ant-input-affix-wrapper {
    padding: 0 8px 0 11px;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
  }
}
.input {
  .ant-row {
    @media screen and (max-width: 992px) {
      margin-left: 0px;
      margin-right: 0px;
    }
  }
}
.setting-drwer {
  display: none;
}
.search-btn {
  .ant-btn {
    padding: 0 0 0 5px;
  }
}
.box-container {
  padding: 8px;
  border: 1px solid gray;
  margin-top: 32px;
}
.text-view {
  display: flex;
  justify-content: space-between;
  label {
    font-size: 16px;
    font-weight: 600;
  }
}
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
  padding-left: 16px !important;
}
.flex-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  .active {
    color: #00c981;
  }
  .nav-button {
    display: none;
  }
}
.flex-nav:hover {
  .nav-button {
    display: flex;
    cursor: pointer;
  }
}
.box-filter{
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgb(0 0 0 / 15%);
    padding-top: 9px;
    display: block;
}
.input-filter{
    margin-top: 12px;
    display: flex;
    justify-content: flex-end;
}
.information{
  color: black;
  padding-top: 12px;
  padding-bottom: 12px;
  .items{
     display: flex;
     justify-content : space-between;
     align-items: center;
     flex-wrap: wrap;
     padding: 10px;
     background: #fff;
     margin:12px 24px;
     >div{
       display: flex;
       justify-content: space-between;
       align-items: center;
       white-space: nowrap;
       width: 40%;
       @media  screen and (max-width:767px){
          width: auto;
       }
       >strong{
          color: green;
          padding-left: 4px;
          width: 60%;
          text-align: left;
          @media  screen and (max-width:767px){
            width: auto;
         }
       }
    }
  }
}
.primary-type{
  background: #fff;
  border: 1px solid #176A5B;
}

.mobile-search{
    @media screen and (max-width:767px){
        display: grid;
        gap: 10px;
        margin-top: 10px;
    }
}
.username{
  .ant-form-item-label > label{
     color: #fff;
  }
}
.card-form{
   position: relative;
   width: 100%;
   height: 100%;
   .poset{
     position: relative;
     top: 43%;
     z-index: 0;
     height: 100%;
     .pos-clinic{
        position: absolute;
        top: -6%;
     }
     .pos-name{
      position: absolute;
      top: 9%;
      @media screen and (max-width:767px) {
          width: 75%;
      }
    }
    .pos-restoration{
      position: absolute;
      top: 25%;
   }
    .pos-valid-to{
      @media screen and (max-width:767px) {
        top: 26%;
        left: 60%;
     }
    }
     .pos-code{
       position: absolute;
       top: 40%;
       @media screen and (max-width:767px) {
        width: 75%;
        top: 44%;
    }
     }
   }
}
.home-search{
   width: 100%;
   height: 50vh;
   display: flex;
   justify-content: center;
   align-items: center;
   .mobile-search{
    display: flex;
    justify-content: space-between;
    // flex-wrap: wrap;
     width: 60%;
      @media screen and (max-width:767px){
          width: 100%;
      }
   }
}
.card-img-overlay{
   .poset{
      @media screen and (min-width: 1000px) {
        font-size: 16px;
        height: 100%;
        .pos-clinic{
            position: absolute;
            top: -5%;
          }
        .pos-name{
            position: absolute;
            top: 10%;
          }
          .pos-restoration{
            position: absolute;
            top: 26%;
          }
          .pos-code{
            position: absolute;
            top: 41%;
            font-size: 16px;
          }
        .pos-valid-to{
           top: 26%
        }
      }
      @media screen and (min-width: 1600px) {
        font-size: 24px;
        height: 100%;
        .pos-clinic{
            position: absolute;
            top: -2%;
          }
        .pos-name{
            position: absolute;
            top: 12%;
          }
          .pos-restoration{
            position: absolute;
            top: 29%;
          }
          .pos-code{
            position: absolute;
            top: 42%;
            font-size: 24px;
          }
        .pos-valid-to{
           top: 28%
        }
      }

   }
}
.ant-picker-cell {
  .ant-picker-cell-inner{
     white-space: nowrap;
  }
}
.details{
.poset{
  @media screen and (min-width: 1000px) {
    font-size: 16px;
    height: 100%;
    .pos-clinic{
        position: absolute;
        top: 0%;
      }
    .pos-name{
        position: absolute;
        top: 14%;
      }
      .pos-restoration{
        position: absolute;
        top: 28%;
      }
      .pos-code{
        position: absolute;
        top: 43%;
        font-size: 16px;
      }
    .pos-valid-to{
       top: 28%
    }
  }
}
}