/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/paroduct/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
@media (min-width: 992px) {
  .layout-dashboard .ant-layout-footer {
    margin: 0 0 20px 20px;
  }
  .layout-dashboard .ant-layout-sider.sider-primary {
    /* margin: 20px 0 0 20px; */
    padding: 33px 20px 13px 20px;
    height: calc(100vh - 20px);
  }
  .layout-dashboard .ant-layout {
    width: auto;
    flex-shrink: 1;
  }
  .layout-dashboard .header-control .sidebar-toggler {
    display: none;
  }
}

@media (min-width: 768px) {
  .layout-dashboard .ant-layout-header {
    margin: 10px 20px;
  }
  .layout-dashboard .header-control {
    margin-top: 0;
  }
  .layout-dashboard .header-control .header-search {
    margin: 0 7px;
  }
  .layout-dashboard .header-control .btn-sign-in span {
    display: inline;
  }
  .profile-nav-bg {
    margin-top: -87.8px;
  }
  .card-profile-head {
    margin: -53px 24px 24px;
  }
}

@media (min-width: 992px) {
  .card-billing-info.ant-card .ant-card-body {
    display: flex;
  }
  .layout-dashboard-rtl {
    overflow: auto;
  }
  .layout-dashboard-rtl .ant-layout-sider.sider-primary {
    margin: 20px 20px 0 0;
    height: calc(100vh - 20px);
  }
  .layout-dashboard-rtl .ant-layout-sider.sider-primary {
    right: 0;
    left: auto;
  }
  .layout-dashboard-rtl .ant-layout-sider.sider-primary {
    right: 0;
    left: auto;
  }
  .layout-dashboard-rtl .ant-layout {
    margin-right: 270px;
    margin-left: 0;
  }
  .layout-dashboard-rtl .ant-layout-footer {
    margin: 0 20px 20px 0;
  }
}

@media (max-width: 768px) {
  .layout-dashboard.layout-dashboard-rtl .header-control {
    justify-content: flex-end;
  }
}



.poset {
  position: absolute;
  float: left;
  top:38%;
  left: 30%;
  z-index: 10;
  text-align: left;
  width: 100%;
  text-shadow: 2px 2px 4px #000000;
  letter-spacing: 2px;
  font-size: x-large;
}


.poset .pos-name {
  position: relative;
  top: 20px;
}

.poset .pos-restoration {
  position: relative;
  top: 40px;
}
.poset .pos-code {
  position: relative;
  top: 60px;
  word-break: break-word;
  color: #8dc63f;
  text-shadow: none;
  letter-spacing: 5px;
  font-size: xx-large;
}
.poset .pos-valid-to {
  position: absolute;
  top:117px;
  left: 56%;
}


@media (max-width: 1600px) {
  .poset {
    font-size: larger;
  }

  .poset .pos-code {
    font-size: x-large;
  }
}


@media (max-width: 1000px) {
  .poset {
    font-size: small;
  }

  .poset .pos-code {
    font-size: small;
    top: 37px;
  }
  .poset .pos-valid-to{
    top: 62px;
  }
  .poset .pos-restoration{
      top: 21px;
  }
  .poset .pos-name{
      top: 11px;
  }
}



@media (max-width: 770px) {
  .poset {
    font-size: small;
  }

  .poset .pos-code {
    font-size:small;
    top: 29px;
  }
  .poset .pos-valid-to{
     top: 58px;
  }
  .poset .pos-restoration{
     top: 15px;
  }
  .poset .pos-name{
     top: 7px;
  }
}


@media  screen and (max-width:767px){
  .poset{
     height: 100%;
  }
  .poset .pos-name {
    top: 4%;
  }
  .poset .pos-restoration {
    top: 12%;
  }
  .poset .pos-code {
    top: 20%;
  }
  .poset .pos-valid-to {
    top:58px;
  }
}

@media (max-width: 500px) {
  .poset {
    font-size: small;
  }

  .poset .pos-code {
    font-size: unset;
  }
}


@media (max-width: 400px) {
  .poset {
    font-size: x-small;
  }

  .poset .pos-code {
    font-size: small;
  }
}

@media (max-width: 300px) {
  .poset {
    font-size: xx-small;
  }

  .poset .pos-code {
    font-size: x-small;
  }
}
